






import { Component, Vue } from 'vue-property-decorator';
import { Franchise, Office, WebsiteLevel, MapSettings } from 'client-website-ts-library/types';
import { API, Config, Logger, LogLevel } from 'client-website-ts-library/services';

const Map = () => import('client-website-ts-library/components/Map/Map.vue');

@Component({
  components: {
    Map,
  },
})
export default class Footer extends Vue {
  private offices: Office[] = [];

  private office: Office | null = null;

  private franchise: Franchise | null = null;

  private mapSettings: MapSettings | null = null;

  mounted() {
    this.loadOffices();
  }

  loadOffices(): void {
    switch (Config.Website.Settings!.WebsiteLevel) {
      case WebsiteLevel.Profile:

        API.Profiles.Get(Config.Website.Settings!.WebsiteId, true).then((profile) => {
          this.office = profile.Office;
        });

        break;
      case WebsiteLevel.Office:

        API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
          this.office = office;
        });

        break;
      case WebsiteLevel.Franchise:

        API.Franchises.GetOffices(Config.Website.Settings!.WebsiteId).then((offices) => {
          this.offices = offices;

          this.mapSettings = new MapSettings({
            Zoom: 20,
            Interactive: true,
            Padding: 80,
            Markers: offices.filter((office) => office.DisplayAddress).map((office) => ({ Address: `${office.AddressLine1} ${office.AddressLine2}` })),
            MaxBounds: {
              NE: {
                Lat: -9.795678,
                Lon: 180,
              },
              SW: {
                Lat: -29.688053,
                Lon: 0,
              },
            },
          });
        });

        API.Franchises.Get(Config.Website.Settings!.WebsiteId).then((franchise) => {
          this.franchise = franchise;
        });

        break;
      default:

        Logger.Log(LogLevel.Error, 'Failed to load office for website. Website level is invalid.');

        break;
    }
  }

  // eslint-disable-next-line
  scrollToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  // eslint-disable-next-line
  get year(): string {
    return new Date().getFullYear().toString();
  }

  get hideMap(): boolean {
    return this.$route.meta?.hideOfficeMap;
  }
}
